div.list_scroll_area {
  background-color: #030E12;
    width: 17vw;
    height: 38vh; 
  }


  div.list_scroll_area > .content {
    width: 200px;
    height: 300px;
  }


  div.list_scroll_area_project {
    background-color: #ffffff12;
      width: 100%;
      height: 100%; 
    }

    div.list_scroll_area_project > .scrollbar-container{
      padding: 0.6%;
      padding-right: 1%;
    }
  
  
    div.list_scroll_area_project > .content {
      width: 100%;
      height: 100%;
    }


    div.list_scroll_dropdown_projects {
      background-color: #ff0000;
        width: 100%;
        height: 100%; 
      }
  
      div.list_scroll_dropdown_projects > .scrollbar-container{
        padding: 2.6%;
        padding-right: 2%;
      }
    
    
      div.list_scroll_dropdown_projects > .content {
        width: 100%;
        height: 100%;
      }
  