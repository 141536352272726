.dashboard_scroll_area {
    width: 500px;
    height: 600px; 
  }


  .dashboard_scroll_area > .content {
    background: green;
    width: 500px;
    height: 600px;
  }